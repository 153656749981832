import * as React from 'react';
import { RichTextRender, useGetAllAboutUs } from 'src/cms';
import { ContentSection } from 'src/components/layout/layout';
import { Title } from './style';

export const AboutUs = () => {
  const [{ aboutUs }] = useGetAllAboutUs();

  return (
    <ContentSection>
      <Title>About Us</Title>
      <RichTextRender data={aboutUs} />
    </ContentSection>
  );
};
