import * as React from 'react';
import { AboutUs } from 'src/components/aboutUs';
import { Layout } from 'src/components/layout/layout';

const AboutUsPage = () => {
  return (
    <Layout>
      <AboutUs />
    </Layout>
  );
};
export default AboutUsPage;
